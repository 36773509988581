import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';
import ReviewStatusHeaderWithIcon from '../../../models/review/header-with-icon';
import { ReviewHelper } from '../../../models/review/review-helper';
import styles from './product-card.module.css';

function ImageWithBlur({
	imgSrc,
	altText,
}: {
	imgSrc: string;
	altText: string;
}) {
	const [imageSrc, setImageSrc] = useState(imgSrc);
	const [imageLoaded, setImageLoaded] = useState(false);

	const handleError = () => {
		setImageSrc('/img/noImage/no-product-card-230x230.png');
	};
	// Generate a random color
	function getRandomImage() {
		const amountOfLoadingImages = 4;
		return `/img/noImage/loading/loading-${Math.floor(
			Math.random() * amountOfLoadingImages + 1
		)}-product-card-230x230.webp`;
	}

	return (
		<Image
			src={imageSrc}
			alt={altText}
			style={{ aspectRatio: '1/1', objectFit: 'cover', width: '100%' }}
			layout='fill'
			// objectFit='cover'
			placeholder='blur'
			blurDataURL={getRandomImage()}
			onLoadingComplete={() => setImageLoaded(true)}
			onError={handleError}
		/>
	);
}

export default function ProductCard({
	id,
	category,
	subcategory,
	name,
	price,
	image,
	editMode = false,
	designerImage,
	desingerName,
	productNSFW,
	showNSFW,
	disabledClick,
	reviewProgress,
}: {
	id: string;
	category: string | string[];
	subcategory: string | string[];
	name: string;
	price: string;
	image: string;
	editMode: boolean;
	designerImage: string;
	desingerName: string;
	productNSFW: boolean;
	showNSFW: boolean;
	disabledClick?: boolean;
	reviewProgress?: number;
}) {
	return (
		<article className={styles.productCard} key={`productCard-${id}`}>
			<Link
				href={
					disabledClick ? '#' : `/products/${category}/${subcategory}/${id}`
				}
				key={`${subcategory}-${id}`}
				passHref
			>
				<a className={styles.productCardLink}>
					<button
						className={
							'btn btn-sm btn-outline-secondary col ' + styles.productCardBtn
						}
						type='button'
					>
						{editMode ? (
							<section className={styles.editMode}>
								<Link
									href={`/user-page/designer-tools/edit-product/${id}`}
									key={`${subcategory}-${id}`}
									passHref
								>
									<a>
										<div
											style={{
												position: 'absolute',
												width: '100%',
												height: 60,
												bottom: 0,
												background: 'rgb(0 0 0 / 25%)',
												color: 'white',
												textAlign: 'center',
											}}
										>
											<a style={{ lineHeight: '40px' }}>Edit</a>
										</div>
									</a>
								</Link>
							</section>
						) : (
							''
						)}
						<section>
							{productNSFW && !showNSFW ? (
								<>
									<div
										style={{ width: 200, height: 200, position: 'relative' }}
									>
										<i
											className='fa-regular fa-eye-slash'
											style={{
												fontSize: 100,
												color: '#777777',
												position: 'absolute',
												top: 100,
												left: 100,
												zIndex: 1,
											}}
										></i>
										<img
											src={'/img/noImage/nsfw-product-card-230x230.jpg'}
											style={{ aspectRatio: '1/1', objectFit: 'cover' }}
											alt={`Product card image of ${name}`}
										></img>
									</div>
								</>
							) : (
								<ImageWithBlur
									imgSrc={
										image !== '/img/noImage/no-product-card-230x230.png' &&
										image !== undefined &&
										image !== null
											? `${process.env.NEXT_PUBLIC_SPACES}/images/${id}/product-card-${image}`
											: '/img/noImage/no-product-card-230x230.png'
									}
									altText={`Product card image of ${name}`}
								/>
							)}

							<section className={styles.productCardInfo}>
								<p className={styles.productCardInfoTitle}>{name}</p>
								<p
									className={styles.productCardInfoPrice}
									style={{
										position: 'absolute',
										top: 0,
										right: -10,
										zIndex: 1,
										background: '#7e0f23',
										color: 'white',
										lineHeight: '25px',
										paddingRight: 20,
										transform: 'skewX(-20deg)',
									}}
								>
									{isNaN(parseFloat(price))
										? ''
										: parseFloat(price) == 0
										? 'Free'
										: `${parseFloat(price)}$`}
								</p>
							</section>
						</section>
						{reviewProgress || reviewProgress == 0 ? (
							<>
								<div
									style={{
										position: 'absolute',
										bottom: 30,
										left: 0,
										width: '100%',
										height: 30,
										background:
											ReviewHelper.InfoBackgroundColor(reviewProgress),
										opacity: 0.8,
									}}
								></div>

								<div
									style={{
										position: 'absolute',
										bottom: 30,
										left: 0,
										width: '100%',
										height: 30,

										paddingLeft: 15,
										lineHeight: '24px',
										color: 'white',
										fontSize: 12,
									}}
								>
									<ReviewStatusHeaderWithIcon reviewProgress={reviewProgress} />
								</div>
							</>
						) : (
							<></>
						)}
					</button>
				</a>
			</Link>
		</article>
	);
}
