export default function ReviewStatusHeaderWithIcon({
	reviewProgress,
	failedReason,
}: {
	reviewProgress: number;
	failedReason?: string;
}) {
	return (
		<>
			{reviewProgress == 0 ? (
				<>
					<p
						style={{
							display: 'inline-block',
						}}
					>
						<i style={{ marginRight: 5 }} className='fa-solid fa-pen-nib'></i>
						Product is not completed
					</p>
				</>
			) : (
				<></>
			)}

			{reviewProgress == 1 ? (
				<>
					<p
						style={{
							display: 'inline-block',
						}}
					>
						<i style={{ marginRight: 5 }} className='fa-solid fa-pause'></i>
						Need updates before review
					</p>
				</>
			) : (
				<></>
			)}

			{/* Checking files quality */}
			{reviewProgress == 2 ? (
				<>
					<p
						style={{
							display: 'inline-block',
						}}
					>
						<i style={{ marginRight: 5 }} className='fa-solid fa-robot'></i>
						Checking Files Quality
					</p>
				</>
			) : (
				<></>
			)}

			{/* waiting for review */}
			{reviewProgress == 3 ? (
				<>
					<p
						style={{
							display: 'inline-block',
						}}
					>
						<i
							style={{ marginRight: 5 }}
							className='fa-solid fa-hourglass-half'
						></i>
						Waiting for manual review
					</p>
				</>
			) : (
				<></>
			)}

			{/* OK */}
			{reviewProgress == 4 ? (
				<>
					<p
						style={{
							display: 'inline-block',
						}}
					>
						{' '}
						<i style={{ marginRight: 5 }} className='fa-solid fa-check'></i>
						Product is live!
					</p>
				</>
			) : (
				<></>
			)}

			{/* Failed */}
			{reviewProgress == 5 ? (
				<>
					<p
						style={{
							display: 'inline-block',
						}}
					>
						<i style={{ marginRight: 5 }} className='fa-solid fa-xmark'></i>
						Current product is not accepted
					</p>
				</>
			) : (
				<></>
			)}
			{(reviewProgress == 1 || reviewProgress == 5) && failedReason ? (
				<p>Reason: {failedReason}</p>
			) : (
				<></>
			)}
		</>
	);
}
