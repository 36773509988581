import moment from 'moment';
import { ProductState } from '../users/designer/edit-product';

export class ReviewHelper {
	public static ProductReadyForReview(
		name: string,
		description: string,
		searchTermsLength: number,
		imageUploadStateLength: number,
		fileUploadStateLength: number,
		productPublic: boolean
	): boolean {
		return (
			name != '' &&
			name != 'New Product' &&
			description != '' &&
			searchTermsLength > 0 &&
			imageUploadStateLength > 0 &&
			fileUploadStateLength > 0 &&
			productPublic
		);
	}

	public static InfoBackgroundColor(reviewProgress: number): string {
		switch (reviewProgress) {
			case 0:
				return '#afafaf';
			case 1:
				return '#ff6000';
			case 2:
				return '#979797';
			case 3:
				return '#0079a7';
			case 4:
				return '#009549';
			case 5:
				return '#ff0000';
			default:
				return '#ff0000';
		}
	}

	public static ReviewProgress = (
		productState: ProductState,
		productReadyForReview: boolean
	): number => {
		console.log('productState', productState);

		if (!productReadyForReview) {
			return 0;
		}

		if (productState.failed) {
			return 5; //Product failed review
		}

		if (productState.lastApproved > productState.lastUpdated) {
			return 4; //Product is live
		}

		if (moment().isBefore(productState.delayReviewUntil)) {
			return 1; //Product is pending manual review
		}

		if (
			productState.lastApproved == null ||
			productState.lastApproved < productState.lastUpdated
		) {
			return 3; //Product is pending manual review
		}

		return 2;
	};
}
