import { useState } from 'react';
import styles from '../../../styles/Home.module.css';
import productStyles from './products.module.css';

import { HomeDataProduct } from '../../../models/api/main-page/get-home-data';
import ProductCard from '../products/product-card';

export default function IndexProducts(props: any) {
	const [productsToView, setProductsToView] = useState<string>('latest');

	return (
		<>
			<section className='row mb-5'>
				<h2 style={{ fontSize: 25, marginBottom: 0 }}>
					{props.title}
					<i
						className={props.icon} //'fa-solid fa-cube'
						style={{
							transform: 'rotate(-45deg)',
							fontSize: 40,
							opacity: 0.2,
							marginLeft: 10,
						}}
					></i>
				</h2>
				<p>{props.description}</p>

				<div>
					<button
						className={styles.mainButton + ` btn col-sm-3 col-md-2 pt-1 pb-1`}
						style={{
							background: productsToView != 'featured' ? 'gray' : '#7e0f23',
							position: 'relative',
							overflow: 'hidden',
						}}
						hidden={props.featuredProducts?.length == 0}
						onClick={() => setProductsToView('featured')}
					>
						Featured
						<i
							className='fa-solid fa-award'
							style={{
								transform: 'rotate(-45deg)',
								fontSize: 40,
								opacity: 0.2,
								position: 'absolute',
								right: 0,
								top: 0,
							}}
						></i>
					</button>
					<button
						className={styles.mainButton + ` btn col-sm-3 col-md-2 pt-1 pb-1`}
						style={{
							color: productsToView != 'latest' ? 'gray' : '#7e0f23',
							boxShadow:
								productsToView == 'latest'
									? 'rgb(212, 212, 212) 5px 5px 5px'
									: 'none',
							position: 'relative',
							overflow: 'hidden',
							background: 'transparent',
						}}
						hidden={props.latestProducts?.length == 0}
						onClick={() => setProductsToView('latest')}
					>
						Latest
						<i
							className='fa-regular fa-clock'
							style={{
								transform: 'rotate(-45deg)',
								fontSize: 40,
								opacity: 0.2,
								position: 'absolute',
								right: 0,
								top: 0,
							}}
						></i>
					</button>
					<button
						className={styles.mainButton + ` btn col-sm-3 col-md-2 pt-1 pb-1`}
						style={{
							color: productsToView != 'popular' ? 'gray' : '#7e0f23',
							boxShadow:
								productsToView == 'popular'
									? 'rgb(212, 212, 212) 5px 5px 5px'
									: 'none',
							background: 'transparent',
							position: 'relative',
							overflow: 'hidden',
						}}
						hidden={props.popularProducts?.length == 0}
						onClick={() => setProductsToView('popular')}
					>
						Popular
						<i
							className='fa-solid fa-fire'
							style={{
								transform: 'rotate(-45deg)',
								fontSize: 40,
								opacity: 0.2,
								position: 'absolute',
								right: 0,
								top: 0,
							}}
						></i>
					</button>
					<button
						className={styles.mainButton + ` btn col-sm-3 col-md-2 pt-1 pb-1`}
						style={{
							color: productsToView != 'random' ? 'gray' : '#7e0f23',
							boxShadow:
								productsToView == 'random'
									? 'rgb(212, 212, 212) 5px 5px 5px'
									: 'none',
							background: 'transparent',
							position: 'relative',
							overflow: 'hidden',
						}}
						hidden={props.randomProducts?.length == 0}
						onClick={() => setProductsToView('random')}
					>
						Random
						<i
							className='fa-solid fa-shuffle'
							style={{
								transform: 'rotate(-45deg)',
								fontSize: 40,
								opacity: 0.2,
								position: 'absolute',
								right: 0,
								top: 0,
							}}
						></i>
					</button>
					<button
						className={styles.mainButton + ` btn col-sm-3 col-md-2 pt-1 pb-1`}
						style={{
							color: productsToView != 'free' ? 'gray' : '#7e0f23',
							boxShadow:
								productsToView == 'free'
									? 'rgb(212, 212, 212) 5px 5px 5px'
									: 'none',
							background: 'transparent',
							position: 'relative',
							overflow: 'hidden',
						}}
						hidden={props.freeProducts?.length == 0}
						onClick={() => setProductsToView('free')}
					>
						Free
						<i
							className='fa-solid fa-hat-wizard'
							style={{
								transform: 'rotate(-45deg)',
								fontSize: 40,
								opacity: 0.2,
								position: 'absolute',
								right: 0,
								top: 0,
							}}
						></i>
					</button>
				</div>

				<section style={{ position: 'relative' }}>
					{productsToView == 'latest' ? (
						<p className='mt-3'>Some of the recent uploads to the platform</p>
					) : (
						<></>
					)}
					{productsToView == 'featured' ? (
						<p className='mt-3'>Staffs favorite products</p>
					) : (
						<></>
					)}
					{productsToView == 'popular' ? (
						<p className='mt-3'>
							Some of the most popular products on the page
						</p>
					) : (
						<></>
					)}
					{productsToView == 'random' ? (
						<p className='mt-3'>Find something random that you might like</p>
					) : (
						<></>
					)}
					{productsToView == 'free' ? (
						<p className='mt-3'>Free products to grab and enjoy</p>
					) : (
						<></>
					)}

					{/* Random products for mobiles */}
					<section className='row mb-5'>
						<section className={productStyles.productArea}>
							{(productsToView == 'latest'
								? props.latestProducts?.length > 0
									? props.latestProducts
									: []
								: productsToView == 'featured'
								? props.featuredProducts?.length > 0
									? props.featuredProducts
									: []
								: productsToView == 'popular'
								? props.popularProducts?.length > 0
									? props.popularProducts
									: []
								: productsToView == 'random'
								? props.randomProducts?.length > 0
									? props.randomProducts
									: []
								: props.freeProducts?.length > 0
								? props.freeProducts
								: []
							).map((product: HomeDataProduct) => (
								<ProductCard
									id={product.id.toString()}
									category={product.productType}
									subcategory={product.category}
									name={product.name}
									price={product.price.toString()}
									image={product.mainImage}
									designerImage={product.designerImage}
									key={`${product.id}`}
									editMode={false}
									productNSFW={false}
									showNSFW={false}
									desingerName={product.desingerName}
								></ProductCard>
							))}
						</section>
					</section>
				</section>
			</section>
		</>
	);
}
